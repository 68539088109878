.layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 25px;
  width: 100%;

  &-left {
    min-width: 20%;
  }

  &-center {
    min-width: 80%;
  }
}

.hero {
  display: flex;
  justify-content: space-between;

  &-bar {
    align-items: center;
    border-radius: 5px;
    background-color: $main-color;
    color: $main-text-color;
    display: flex;
    justify-content: left;
    text-align: left;
    min-height: 60px;
    min-width: 70%;
    position: relative;

    &-picker {
      cursor: pointer;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      position: absolute;
      display: flex;
      align-items: center;
    }

    #hero-bar-input {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 39px;
    }
  }

  .user-profile {
    align-items: center;
    background: white;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    padding: 10px 25px;
    position: relative;

    &:hover {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      .sub-menu {
        visibility: visible;
      }
    }

    .username {
      svg {
        margin-left: 10px;
      }
    }

    p {
      font-size: 14px;
    }

    .sub-menu {
      bottom: -55px;
      background-color: white;
      width: 100%;
      left: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px 25px;
      position: absolute;
      visibility: hidden;
      text-align: left;

      &-item {
        padding-left: 25px;

        a {
          text-decoration: none;
          color: $main-text-color;

          &:hover {
            color: black;
          }
        }

        margin-bottom: 10px;
      }
    }
  }

  button {
    align-self: center;
    border-radius: 5px;
    background-color: $main-color;
    border: 2px solid $main-color;
    color: white;
    cursor: pointer;
    display: block;
    font-weight: 400;
    font-size: 14px;
    min-height: 45px;
    min-width: 100px;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $main-text-color;
    }
  }
}

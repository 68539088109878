@import 'bootstrap/dist/css/bootstrap.css';
@import './variables.scss';
@import '../../containers/Login/Login.scss';

// components
@import '../../components/ReportForm/ReportForm.scss';
@import '../../components/Layout/Layout.scss';
@import '../../components/Hero/Hero.scss';
@import '../../components/Footer/Footer.scss';
@import '../../components/Sidebar/Sidebar.scss';
@import '../../components/Reports/Reports.scss';
@import '../../components/TeamManagementContent/TeamManagementContent.scss';
@import '../../components/Report/Report.scss';
@import '../../components/ProfileInfo/ProfileInfo.scss';
@import '../../components/ChangePassword/ChangePassword.scss';
@import '../../components/ProfileCard/ProfileCard.scss';
@import '../../components/Spinner/Spinner.scss';

// containers
@import '../../containers/Home/Home.scss';
@import '../../containers/Login/Login.scss';
@import '../../containers/NotFound/NotFound.scss';
@import '../../containers/Profile/Profile.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(0deg, #f4f7f7 0%, #f4f7f7 100%), #fff !important;
  margin: 0 !important;
  font-family: 'Inter', sans-serif !important;
}

ul {
  padding-left: 0 !important;
}

ul li {
  list-style: none;
  margin: 0;
}

.ReactModalPortal {
  .ReactModal__Overlay {
    .ReactModal__Content {
      max-height: 80vh;
    }
  }
}

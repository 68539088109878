.reports-wrapper {
  border: 1px solid $gray-mercury;
  border-radius: 5px;
  background-color: $white;
  margin-top: 50px;
  max-width: 80%;
  position: relative;

  .id {
    width: 5%;
  }

  .name {
    width: 25%;
    display: flex;
    justify-content: flex-start;
  }

  .hours {
    width: 15%;
    display: flex;
    justify-content: center;
  }

  .status {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding: 2px 5px;
    }

    .IN_PROGRESS {
      color: $yellow;
    }

    .SEND_REPORT,
    .SENT {
      color: #228b22;
    }
  }

  .subscribe {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 5%;

    input {
      cursor: pointer;
    }
  }

  .actions {
    width: 20%;
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 15px;
      padding: 5px 15px;
      border-radius: 5px;
      background-color: $main-color;
      border: 2px solid $main-color;
      color: $white;
      cursor: pointer;
    }
  }

  .submit {
    width: 20%;
    display: flex;
    justify-content: center;

    button {
      border-radius: 5px;
      background-color: transparent;
      border: 2px solid $main-color;
      padding: 2.5px 10px;
      color: $main-color;
      cursor: pointer;
    }
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
    padding: 0px 20px;
  }

  .table-body {
    padding-top: 20px;

    &-row {
      border-bottom: 1px solid $gray-mercury;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 0px 20px;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      &-item {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }

    .not-found {
      text-align: left;
      margin-bottom: 20px;
      padding-left: 20px;
    }
  }

  .loader {
    width: 100%;
    height: 30vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sidebar {
  border-radius: 5px;
  background-color: $main-color;
  color: $white;
  margin-right: 50px;
  min-height: 90vh;
  padding: 50px 10px 0px 30px;

  &-list {
    &-item {
      &:first-child {
        margin-bottom: 40px;
      }
      margin-bottom: 20px;

      a {
        color: white;
        text-decoration: none;

        &.active {
          color: $main-text-color;
        }
      }
    }
  }
}

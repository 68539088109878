.profile-card {
  padding: 30px 25px;
  text-align: center;

  .avatar {
    align-items: center;
    border: 1px solid $gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 80px;
    margin: 0 auto;
    width: 80px;
  }

  .username {
    padding-top: 10px;
  }
}

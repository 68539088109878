.team-management-wrapper {
  max-width: 80%;

  .tech-leads {
    select {
      cursor: pointer;
      padding: 10px 15px;
    }
  }

  .employees {
    position: relative;

    .list-group {
      &-item {
        display: flex;
        width: 100%;

        .id {
          width: 5%;
        }

        .name {
          width: 26.6%;
          display: flex;
          justify-content: flex-start;
        }

        .email {
          width: 26.6%;
          display: flex;
          justify-content: flex-start;
        }

        .position {
          width: 26.6%;
          display: flex;
          justify-content: center;
        }

        .actions {
          width: 15%;
          display: flex;
          justify-content: center;

          input {
            cursor: pointer;
          }
        }
      }
    }

    .loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
    }
  }

  .save {
    margin-top: 20px;
    width: 100%;

    button {
      background-color: #163339;
      border: 2px solid #163339;
      border-radius: 5px;
      display: block;
      color: $white;
      margin-left: auto;
      padding: 5px 10px;
    }
  }
}

.not-found-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  height: 100%;
  width: auto;

  h1 {
    color: $orange-hover;
  }
}

.report-wrapper {
  .card-title {
    display: flex;
    justify-content: space-between;
  }

  .report-details {
    &-header {
      display: flex;
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 10px;
      margin-bottom: 20px;

      &-item {
        padding: 15px;
      }
    }

    .total-tracked-hours {
      display: flex;
      justify-content: flex-end;
      margin-top: 150px;
    }
  }

  .sub-title {
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
  }
}

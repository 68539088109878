/**
 * Variables
 */

// ============================================================================= Breakpoints
// @include mq(tablet)
$breakpoints: (
  laptop-large-min: '(min-width : 1441px)',
  laptop-large: '(max-width : 1440px)',
  laptop-min: '(min-width : 1201px)',
  laptop: '(max-width : 1200px)',
  tablet-large-min: '(min-width : 1025px)',
  tablet-large: '(max-width : 1024px)',
  tablet-min: '(min-width : 769px)',
  tablet: '(max-width : 768px)',
  phone-larg-md: '(max-width : 600px)',
  phone-larg-min: '(min-width : 550px)',
  phone-large: '(max-width : 480px)',
  phone: '(min-width : 375px)',
  phone-small: '(max-width : 374px)',
);

// ============================================================================= Colors
// background-color: palette(main, base);
$main-color: #15528e;
$blue-light: #e7eef8;
$black: #000000;
$dark-gray: #b0b0b0;
$gray: #969696;
$bright-gray: #efefef;
$lavender: #ebeaea;
$lavender-second: #e1ebf9;
$light-pink: #898686;
$onyx: #393939;
$gray-medium: #444444;
$gray-medium_75: #6a6a6a;
$gray-medium_50: #73838a;
$gray-mercury: #e6e6e6;
$gray-light: #f7f7f7;
$light-grayish-blue: #f0f2f5;
$platinum: #e7e7e7;
$red-opacity: rgba(228, 74, 74, 0.17);
$orange: #e44a4a;
$white: #fff;
$white-smoke: #f5f5f5;
$anti-flash-white: #f3f3f3;
$green: #006400;
$forest-green: #228b22;

$raisin-black: #212121;
$charleston: #272727;
$mostly-black: #001d29;
$arsenic: #434343;
$dove-gray: #6b6a6a;
$dove-gray-second: #6b6b6b;
$dark-gray-second: #3c3c3c;
$orange-hover: #cd2c2c;
$orange-hover-dark: #ba3a3a;
$whisper: #ececec;
$light-gray: #d4d4d4;
$yellow: #fbb13c;
$nero: #262626;
$bright-gray-second: #eaeaea;
$silver-chalice: #acacac;
$gainsboro: #e2dfdf;
$spanish-gray: #9e9e9e;
$platinum-second: #e3e3e3;
$forest-green: #787676;
$dark-grayish: #373636;
$approx-snow: #fbf9f9;
$quick-silve: #a1a1a1;
$gainsboro-second: #e3e2e2;
// ============================================================================= Fonts

$mainFont: 'Inter', sans-serif;

$main-color: #163339;
$main-text-color: #a3997a;
$submit-color: #414bb2;

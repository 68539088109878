.login {
  align-items: center;
  background-color: $white-smoke;
  display: flex;
  min-height: 100vh;
  width: 100%;

  &-form {
    background-color: $white;
    border-radius: 10px;
    display: block;
    height: auto;
    margin: 0 auto;
    margin-bottom: 150px;
    padding: 30px;
    width: 400px;

    &-title {
      margin-bottom: 20px;
    }

    &-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      &-error {
        color: $orange-hover;
        margin-top: 5px;
        padding-left: 5px;
      }

      input {
        border: 1px solid $bright-gray;
        border-radius: 5px;
        padding: 10px 20px;

        &.error {
          border-color: $orange-hover;
        }
      }
    }

    &-button {
      cursor: pointer;
      background-color: $mostly-black;
      border-radius: 5px;
      color: $white;
      margin-top: 20px;
      padding: 5px 30px;
      width: fit-content;
    }
  }
}

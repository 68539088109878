.report-form-wrapper {
  margin-top: 50px;
  display: flex;

  .report-form {
    border: 1px solid $gray-mercury;
    border-radius: 5px;
    background-color: $white;
    min-width: 80%;
    padding: 50px 30px 10px;
    overflow-x: auto;
    min-height: 75vh;
    position: relative;

    &-header {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      &-item {
        margin-bottom: 15px;
        width: 250px;

        &.time,
        &.action {
          width: 100px;
        }
      }
    }

    &-field {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &-item {
        input,
        .disable {
          border: 1px solid $gray-mercury;
          border-radius: 5px;
          width: 250px;
          padding: 10px;
        }

        .disable {
          background: #fafafa;
        }

        .select {
          width: 250px;
          height: 46px;

          &.selected {
            .css-1jqq78o-placeholder {
              color: black !important;
            }
          }

          .css-13cymwt-control,
          .css-t3ipsp-control {
            height: 46px;
          }
        }

        &.time,
        &.action {
          width: 100px;

          input {
            width: 100px;
          }

          .disable {
            width: 100px;
          }
        }

        &.action {
          align-items: center;
          cursor: pointer;
          display: flex;
          padding-left: 20px;

          &:hover {
            color: $orange-hover;
          }
        }
      }
    }

    .form-buttons {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: $submit-color;
        border: 1px solid $gray-light;
        border-radius: 5px;
        color: $white;
        cursor: pointer;
        display: block;
        margin-top: 50px;
        padding: 10px 25px;

        &.send {
          margin-left: 10px;
          background-color: $main-color;
          border: 2px solid $main-color;

          &:hover {
            color: $main-text-color;
          }
        }
      }
    }
  }

  .add {
    margin-left: 10px;
    margin-top: 85px;

    button {
      border: 1px solid $gray-light;
      background-color: $main-color;
      border-radius: 5px;
      color: $main-text-color;
      cursor: pointer;
      padding: 10px 20px;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $white;
      }
    }
  }

  .total-worked-time {
    background-color: $white;
    height: fit-content;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.modal-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  button {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 15px;
  }

  .submit {
    background-color: $main-color;
    border: 2px solid $main-color;
    color: $white;
    margin-left: 10px;

    &:hover {
      color: $main-text-color;
    }
  }

  .cancel {
    background-color: $white;
    border: 2px solid $main-color;

    &:hover {
      color: $main-text-color;
      border-color: $main-text-color;
    }
  }
}
